import React from "react"
import { Box, Grid, Text, Image } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"

import albatrossPendantImage from "../images/treasure/albatross-pendant.jpg"

const AlbatrossPressPage = () => {
  return (
    <Layout>
      <SEO title="Albatross Press" />


      <Grid variant="base" columns={[1, 2, "2fr 1fr"]} sx={{ alignItems: "center" }}>
        <Box>
          <Box sx={{ mx: [-2, -4, -6] }}>
            <Breadcrumbs title="The Albatross Press" headline="A new page for adventure" />
          </Box>
          <Text as="p" variant="bodyLarge">The Albatross Press is the omniverse's paper of record.</Text>
          <Text as="p" variant="bodyLarge">This page will be updated with multimedia tie-ins that are referenced in the Quest Treasure Catalog.</Text>
        </Box>

        <Image src={albatrossPendantImage} alt="Brell's Consumer Magic Show" sx={{ maxWidth: 400, mx: "auto" }} />
      </Grid>
    </Layout>
  )
}

export default AlbatrossPressPage